import React, { Fragment } from "react"
import PageLayout from '../../../layouts/page-layout';
import Spacing from '../../../components/spacing';
import {graphql} from "gatsby";

const Page = ({data}) => {
  return (
    <PageLayout
      data={data} title="Ignite Heating Spares Ltd"
      description="Near Ware, Hertfordshire"
    >
      <p>something</p>
      <Spacing>

      </Spacing>

        <div className="row">
          <div className="span5">
            <p><strong>Address:</strong></p>
            <address>
              Ignite Heating Spares Ltd<br />
              2B High Street<br />
              Ware<br />
              Hertfordshire<br />
              SG12 8AB<br />
            </address>

            <span title="Phone"><strong>Phone:</strong> 01920 871 711</span><br />
            <span title="Website"><strong>Website:</strong><br />
				www.igniteheatingspares.co.uk
				</span><br />
            <span title="Website"><strong>Twitter:</strong><br />
				</span><br />
          </div>

          <div className="span3">
            <img alt="Recommended site"
                 src="/img/badges/recomm2-200.png"/><br />

          </div>
        </div>


    </PageLayout>
  );
};

export default Page;

export const query = graphql`
  query {
    profile: profileYaml {
      ...ProfileFragment
    }
    site {
      siteMetadata {
        title,
        showThemeLogo
      }
    }
  }
`
